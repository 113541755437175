import React from 'react'
import { Link } from 'gatsby'
import { Root } from 'templates/layout'
import { DesignRow, IntenseText } from 'components/design'
import RightArrow from 'svg/right-arrow'

const NotFoundTpl = ({ title, paragraph, groupedLinks }) => (
	<Root
		hideFooter
		desktopMenuColor="text-black"
		desktopMenuBg="bg-transparent"
		desktopWordmarkPosition="static"
	>
		<div className="gradient-pattern-404 h-screen w-full overflow-hidden">
			<DesignRow
				className="mt-32 lg:mt-64"
				leftColumn={<h1 className="leading-tight">{title}</h1>}
				rightColumn={
					<IntenseText className="mb-10 mt-8 lg:pt-16">{paragraph}</IntenseText>
				}
			/>
			{groupedLinks.map((group, index) => (
				<DesignRow
					leftColumn={LinkItem(group.left)}
					rightColumn={LinkItem(group.right)}
					className="mb-7 md:mb-10"
					key={`group-${index}`}
				/>
			))}
		</div>
	</Root>
)

const LinkItem = (link) =>
	link ? (
		<Link
			className="flex items-center justify-between py-2 text-sm font-bold uppercase tracking-widest text-black hover:text-accent sm:py-0"
			key={link.url}
			to={link.url}
		>
			<span>{link.text}</span>
			<span className="w-8">
				<RightArrow />
			</span>
		</Link>
	) : null

export default NotFoundTpl
