import React from 'react'
import {graphql} from 'gatsby'
import {
	componentResolverFromMap,
	withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'
import {linkResolver} from 'utils/link-resolver'
import BlogPostPage from 'pages/blog/{PrismicBlogPost.uid}'
import NotFoundPageTpl from 'templates/404.tpl'

const NotFoundPage = ({data}) => {

	const {title, paragraph, body} = data.prismic404Page.data
	const links = body.map((item) => {
		return {
			url: item.primary.url.url.replace('https://www.cuubstudio.com', ''),
			text: item.primary.text,
		}
	})

	const content = {title, paragraph, groupedLinks: []}
	for (let i = 0; i < links.length; i += 2) {
		content.groupedLinks.push({
			left: links[i] || null,
			right: links[i + 1] || null,
		})
	}

	return <NotFoundPageTpl {...content} />
}

export const query = graphql`
	query notFoundQuery {
		prismic404Page {
			_previewable
			data {
				body {
					... on Prismic404PageDataBodyInternalLink {
						slice_type
						primary {
							text
							url {
								url
							}
						}
					}
				}
				paragraph
				title
			}
		}
	}
`

export default withPrismicUnpublishedPreview(NotFoundPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
		componentResolver: componentResolverFromMap({
			blog_post: BlogPostPage,
		}),
	},
])
